import React, { useState } from "react";
import { Stack, Typography, Button, TextField } from "@mui/material";
import { useDialog } from "contexts/DialogProvider";
import { useHistory } from "react-router-dom";

const ReportBugDialog = ({ errorMessage, errorDetails }) => {
  const [userMessage, setUserMessage] = useState("");
  const { closeDialog } = useDialog();
  const history = useHistory();

  const handleSendEmail = () => {
    const subject = encodeURIComponent("Bug Report");
    const body = encodeURIComponent(
      `Error Message: ${errorMessage}\n\n Error Details are below:\n\n Error at:${errorDetails?.url},\n\n error status :${errorDetails?.status},\n\n\n Error method:${errorDetails?.method}\n\nUser Message: ${userMessage}\n\n`
    );
    const mailtoLink = `mailto:support@kayana.co.uk?subject=${subject}&body=${body}`;
    window.location.href = mailtoLink;
    closeDialog();
    setTimeout(() => {
      history.push({
        pathname: "/dashboard",
      });
    }, 500);
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      {/* Header */}
      <Typography variant="h6">Report an Issue</Typography>

      {/* Error Message */}
      <Typography variant="body1" sx={{ color: "red" }}>
        {errorMessage || "An unexpected error occurred."}
      </Typography>

      {/* Input Field */}
      <TextField
        label="Describe the issue"
        placeholder="Provide details about what you were doing when the error occurred..."
        multiline
        rows={4}
        fullWidth
        value={userMessage}
        onChange={(e) => setUserMessage(e.target.value)}
        variant="outlined"
      />

      {/* Buttons */}
      <Stack direction="row" justifyContent="flex-end" spacing={2}>
        <Button onClick={closeDialog} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleSendEmail}
          color="primary"
          variant="contained"
          disabled={!userMessage.trim()} // Disable if no message
        >
          Submit
        </Button>
      </Stack>
    </Stack>
  );
};

export default ReportBugDialog;
