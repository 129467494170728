let dialogMethods = {};

export const setDialogMethods = (methods) => {
  dialogMethods = methods;
};

export const getDialogMethods = () => {
  if (!dialogMethods.openDialog) {
    throw new Error(
      "Dialog methods are not set. Ensure DialogProvider is initialized."
    );
  }
  return dialogMethods;
};
